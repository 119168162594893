import React from "react";
// import { graphql } from "gatsby";
import Seo from "../components/seo";
import ContentPoi from "../components/ContentPoi";
import Aside from "../components/Aside";
import sanityClient from "@sanity/client";
import sanityConfig from "../../config/sanity";

const Poi = ({ serverData }) => {
  const { slug, seo } = serverData;
  // console.log(serverData);
  return (
    <div className='poi'>
      {seo && (
        <Seo
          pageTitle={seo.metaTitle}
          pageDescription={seo.metaDescription}
          pageImage={seo.metaImage?.asset.url}
          template={`template-poi poi-${slug.current}`}
          page={true}
          noIndex={true}
        />
      )}
      <Aside autoPop={true}>
        <ContentPoi input={serverData} />
      </Aside>
    </div>
  );
};

export default Poi;

// const getDraft = (data) =>
//   data.find((item) => item._id.startsWith(`drafts.`)) || data[0];

const getPoiAndGetServerData = async ({ slug = "house-1" }) => {
  const client = sanityClient({
    projectId: sanityConfig.sanity.projectId,
    dataset: "production",
    apiVersion: "2021-08-29",
    useCdn: true,
    withCredentials: true,
    token: process.env.GATSBY_SANITY_READ_TOKEN,
  });

  const query =
    '*[_type == "poi" && slug.current == $slug && (_id in path("drafts.**"))][0]{ ..., modules[]{_type,_key,caption,texte,image{asset-> },url,video{asset-> }},tags[] -> {title,slug} }';
  const params = { slug: slug };
  const data = await client.fetch(query, params);

  return {
    props: data,
  };
};

export const getServerData = async (context) => {
  // console.log(context);
  try {
    return getPoiAndGetServerData(context.pageContext);
  } catch (error) {
    console.error(error);
    return {
      headers: {
        status: 500,
      },
      props: {},
    };
  }
};
